import React from "react"
import { Switch, Route, Redirect, NavLink, useLocation } from "react-router-dom"
import { AppBar, Toolbar, Typography } from "@material-ui/core"
import { ErrorRouter } from "../components/error-router"
import { ADPSubscriptionMappings } from "./mappings/ADPSubscriptionMapping"
import { JobConfigView } from "./job-config-view"
import { JobConfigNew } from "./job-config-new"
import { JobExecutionLogList } from "./job-execution-log-list"
import { JobConfigEdit } from "./job-config-edit"
import { FileUpload } from "./file-upload"
import { useAuth0 } from "../components/Auth/Auth0Provider"
import { LoadingPage } from "../components/Auth/LoadingPage"
import { LandingPage } from "../components/Auth/LandingPage"
import { IncomingJobMap } from "./mappings/IncomingJobMap"
import { PaycorAuthLanding } from "./PaycorAuthLanding"
import { IntegrationMaps } from "./integrationMaps"
import { PaycorPerformDepartmentMappings } from "./mappings/PaycorPerformDepartmentMappings"
import { PaycorPerformCredentialMappings } from "./mappings/PaycorPerformCredentialMapping"

export type AppProps = {}
export type AppState = {}

export const App = () => {
  const styles: { [name: string]: React.CSSProperties } = {
    toolLink: {
      marginLeft: "1em",
      marginRight: "1em",
      color: "#fff"
    },
    sectionHeader: {
      marginLeft: "3em",
      marginRight: 0,
      border: "1px white"
    }
  }
  const location = useLocation()
  const { loading, isAuthenticated } = useAuth0()
  const isPaycorRedirect = React.useMemo(() => location.pathname.includes("PaycorAuthorization"), [location])

  const appPage = (
    <React.Fragment>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" color="inherit">
            Integration Configurations
          </Typography>
          <Typography variant="body1" color="inherit" style={styles.sectionHeader}>
            Schedule 1 &gt;
          </Typography>
          <NavLink to="/" color="inherit" style={styles.toolLink}>
            Home
          </NavLink>
          <NavLink to="/new" color="inherit" style={styles.toolLink}>
            New Configuration
          </NavLink>
          <Typography variant="body1" color="inherit" style={styles.sectionHeader}>
            Schedule 2 &gt;
          </Typography>
          <NavLink to="/upload" color="inherit" style={styles.toolLink}>
            Upload
          </NavLink>
          <Typography variant="body1" color="inherit" style={styles.sectionHeader}>
            Other &gt;
          </Typography>
          <NavLink to="/integrationMaps" color="inherit" style={styles.toolLink}>
            Integration Maps
          </NavLink>
          <NavLink to="/logs" color="inherit" style={styles.toolLink}>
            Job Logs
          </NavLink>
        </Toolbar>
      </AppBar>
      <Switch>
        <Route exact path="/">
          <JobConfigView />
        </Route>
        <Route path="/edit/:id" component={JobConfigEdit} />
        <Route exact path="/new" component={JobConfigNew} />
        <Route exact path="/upload" component={FileUpload} />
        <Route path="/employeeJobCodeMappings" component={IncomingJobMap} />
        <Route path="/facilityMappings" component={IncomingJobMap} />
        <Route path="/flsaCodeMappings" component={IncomingJobMap} />
        <Route path="/punchJobCodeMappings" component={IncomingJobMap} />
        <Route path="/PaycorPerformDepartmentMappings" component={PaycorPerformDepartmentMappings} />
        <Route path="/integrationMaps" component={IntegrationMaps} />
        <Route path="/ADPSubscriptionMapping" component={ADPSubscriptionMappings} />
        <Route path="/PaycorPerformCredentialMapping" component={PaycorPerformCredentialMappings} />
        <Route path="/logs" component={JobExecutionLogList} />
        <Route path="/error" component={ErrorRouter} />
        <Route exact path="/PaycorAuthorization" component={PaycorAuthLanding} />
        <Route path="*">
          <Redirect to="/error/404" />
        </Route>
      </Switch>
    </React.Fragment>
  )

  const paycorAuthPage = (
    <React.Fragment>
      <Route exact path="/PaycorAuthorization" component={PaycorAuthLanding} />
    </React.Fragment>
  )

  return (
    <>
      {loading && <LoadingPage />}
      {isPaycorRedirect && !loading && paycorAuthPage}
      {!isPaycorRedirect && !isAuthenticated && !loading && <LandingPage />}
      {!isPaycorRedirect && isAuthenticated && !loading && appPage}
    </>
  )
}
