export enum MapTypeEnum {
  EmpJobCode,
  PunchJobCode,
  FlsaCode,
  FacilityMapping
}
export enum FlsaCodeEnum {
  Exempt,
  NonExempt,
  Contractor
}
export interface APIMapInterface {
  id?: string
  action: () => string
  pathname: string
  map?: IncomingJobMapInterface | FacilityInterface | JobCodeInterface | FlsaCodeInterface
}
export interface IncomingJobMapInterface {
  id?: string
  customerCode: string
  tableId: string
  companyId?: string
  communityId?: string
}

export interface FacilityInterface {
  id?: string
  companyId: string
  communityId: string
  facilityId: string
}
export interface JobCodeInterface {
  id?: string
  customerJobCode: string
  jobFunctionId: string
  communityId: string
}
export interface FlsaCodeInterface {
  id?: string
  customerFlsaCode: string
  flsaValue: number | MapTypeEnum
  communityId: string
}
